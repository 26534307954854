import React, { useState, useRef } from "react";
import { Link,graphql } from "gatsby";
import Layout from "components/layout";
import SEO from "components/seo";
import GetInTouch from "components/getInTouch";
import Pagination from "components/TranslatorHub/pagination";

export const translatorListQuery = graphql`
  query translatorListQuery($limit: Int!, $skip: Int!) {
    allTranslators(
      sort: { fields: [data___seo_project_create_date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          data {
            seo_project_title
            seo_project_meta_desc
            seo_project_meta_keywords
            slug
            title_x
            translator_name
            translator_place
            translator_service
            translator_desc
            seo_h1
            seo_project_content
          }
        }
      }
    }
  }
`;


function TranslatorsList(props) {
    const title = "Our Professional Translators | Tomedes";
    const keywords = "translation, translation service, document translation";
    const description =
      "A Network of Professional Translators - Our experts offer over 200 language pairings, enabling us to provide top quality translations to clients arount the world.";
    const TranslatorsListData = props.data.allTranslators.edges;
    var canonical = "/translators";
    if(props.pageContext.currentPage !== 1){
      canonical = "/translators/p/"+props.pageContext.currentPage;
    }

  return (
    <Layout>
      <SEO
        title={title}
        keywords={keywords}
        description={description}
        slug={canonical}
      />
      <div className="bg-midBlue pt-[20px] pb-[100px]">
        <div class="content-container text-white w-9/12 flex font-secondary flex-col justify-start mx-auto py-10">
          <p class="text-[18px] font-opensans py-[100px]">
            Tomedes' reputation for quality lives and dies by the quality of our
            translators. As such, we have rigorous systems in place to ensure that
            we recruit and retain outstanding linguists who can deliver at the
            level required to meet our and our clients' expectations.
          </p>
          <h2 class="text-white text-[35px] pb-[40px] ">
            How we recruit translators
          </h2>
          <p class="font-opensans">
            We recruit translators from around the world in order to build up a
            vast network of language pairings and skills. We use native speakers
            of the target language when we translate clients' documents, so we
            need a wide range of language pairings to be available in our network.
            We also match our clients' jobs to our translators' skills, to make
            sure that each client benefits from sector-specific knowledge.
          </p>
          <p class="font-opensans py-[40px]">
            As such, our recruitment process doesn't just assess each translator
            based on their linguistic ability, but also on the breadth of their
            experience and any specialist knowledge that they can demonstrate. We
            then award each job to the translator that most closely meets the
            requirements for that particular piece of work, in order to maintain
            our high quality standards at all times.
          </p>

          <div class="">
            <img
              src="https://skins.tomedes.com/frontend/images/translators/translators.jpg"
              alt=""
            class="sm:h-[30rem] sm:w-[60rem]"
            />
          </div>
          <h2 class="text-white text-[35px] pb-[40px] pt-[100px]">
            Network of expert translators
          </h2>
          <p class="font-opensans">
            We keep tabs on where our translators live in order to ensure that
            they are assigned work that meets their local environment. This means
            that a translation into English could be assigned to a translator
            living in the UK, the USA, Canada or Australia, depending on which
            version of English suited the client's needs. It's our way of ensuring
            that our translators keep up to speed with local language developments
            from new vocabulary to evolving word usage.
          </p>
          <p class="font-opensans py-[40px]">
            In addition, our focus on expertise means that we have a host of legal
            translation experts, medical translation professionals, marketing
            translation gurus and more in our network. We take this experience
            into account when assigning every translation job, so that each of our
            clients can benefit from the services of a translator with specialist
            skills that are specifically suited to that client's documents. You
            can read more here about the other ways in which we guarantee the
            quality of our translations.
          </p>
        </div>        
      <div className="w-11/12 xl:w-full max-w-6xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {TranslatorsListData?.map(({ node }) => {
                    const { data } = node;
                    const { slug,translator_name,translator_place,translator_service,translator_desc } = data;
                    return (
                    <>
                        <div className="card h-[382px] w-[366px] bg-black text-white rounded-xl">
                          <div className="flex justify-between py-4 px-4">
                            <div className="flex flex-col">
                              <span className="text-[24px] mb-1">{translator_name}</span>
                              <span className="text-[20px] mb-1">{translator_place}</span>
                              <span className="text-[16px]">{translator_service}</span>
                            </div>
                            <div className="flex">
                              <div className=" border-l-2 mr-2 bg-newGray h-24"></div>
                              <div className="flex flex-col items-center">
                                <div className="">
                                  <a
                                    href={slug}
                                    className="rounded-full px-4 py-1 mt-6 inline-block font-sans text-[16px] font-light bg-lightBlue"
                                  >
                                    Read more
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="text-[17px] leading-6 italic text-center px-2">{translator_desc}</p>
                        </div>
                    </>
                    );
            })}
          </div>
          <Pagination
              totalPages={props.pageContext.totalPages}
              currentPage={props.pageContext.currentPage}
              baseUrl="/translators"
            />
        </div>
      </div>
      <GetInTouch />
    </Layout>
  );
}

export default TranslatorsList;
